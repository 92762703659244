<!-- 金币商城路由页面 -->
<template>
	<el-container id="content">
		<Top></Top>
		<el-header>
			<GoldMallHeader class="position-relative" style="z-index: 2;"></GoldMallHeader>
		</el-header>
		<el-main>
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <!-- 除了需要缓存的路由 还要创建一个非缓存路由的入口-->
      <router-view v-if="!$route.meta.keepAlive"></router-view>
		</el-main>
		<el-footer>
			<Footer></Footer>
		</el-footer>
		<Suspension></Suspension>
	</el-container>
</template>

<script>
	import parm from '@/components/loyout/publicList'
	import GoldMallHeader from '@/components/GoldMall/GoldMallHeader'
	export default {
		components: {
			GoldMallHeader
		},
		data() {
			return {};
		},
		computed: {},
		mounted() {},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
	#app .el-header {
		margin-top: 0;
		height: 188px !important;
		padding: 0;
	}

	#app .el-main {
		min-height: 550px;
		min-width: 1100px;
		// margin: 0 auto;
		// background: #f8f9fe;
		display: flex;
		justify-content: center;
		padding: 0;
		background-color: #FFFFFF;
		overflow: hidden;
	}

	.page-box {
		background-color: #f8f9fe;
	}

	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.bg-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
		}
	}
</style>
