<template>
	<div :class="themeClass" class="width-fill">
		<div class="gold-mall-head-box bg-fff position-relative">
			<div class="gold-mall-head-tatle-box maink flex-row-align-center">
				<router-link to="/">
					<img :src="logoUrl" style="height: 58px;width:182px;background-size:cover;" />

				</router-link>
				<div class="so marato search-box">
					<el-input placeholder="请输入搜索内容" v-model="search" @keyup.native.enter="searchClick($event)">
						<div class="search-button pointer font-color-fff font-size15" slot="append" @click="searchClick($event)">搜索</div>
					</el-input>
				</div>
				<div class="so mar20">
					<div class="font-size12 font-color-333">客服热线（周一至周五9:00-22:00）</div>
					<div class="flex-row-space-between margin-t-5">
						<div class="LandlineImage">
							<imageBox :src="require('@/assets/Landline.gif')"></imageBox>
						</div>
						<div class="font-size22 margin-r-5 font-weight700 color-theme  ">
							400-666-3439
						</div>
					</div>
				</div>
			</div>
			<div class="table-box position-relative flex-row-align-flex-end">
				<div class="table-inner-box maink position-relative flex-row">
					<div class="flex-row">
						<router-link to="/">
							<div class="domain-box flex-column-center-center pointer"  @click='deleteSession'>
								<i class="iconfont icon-shouye font-color-fff font-size20"></i>
								<div class="margin-t-6 font-size14 font-color-fff">平台首页</div>
							</div>
						</router-link>
						<div class="domain-box flex-column-center-center position-relative pointer" :class="indexSelected?'choose-style':''"
						 @click="selectIndex()">
							<i class="iconfont icon-jifenshangcheng font-color-fff" :class="indexSelected?'font-size24':'font-size20'"></i>
							<div class="margin-t-6 font-size14 font-color-fff">金币商城首页</div>
							<div v-if="indexSelected" class="choose-box-triangle position-absolute"></div>
							<div v-if="indexSelected" class="choose-box-rectangle position-absolute"></div>
						</div>

						<div v-for="(item,index) of goldMallList1" :key="index" class="domain-box flex-column-center-center position-relative pointer"
						 :class="currentTab==item.goodsClass.fGoodsClassID?'choose-style':''" @click="selectCate(item)">
							<i class="font-color-fff" :style="currentTab==item.goodsClass.fGoodsClassID?'font-szie:24px':'font-szie:20px'" :class="item.annex" ></i>
							<div class="margin-t-6 font-size14 font-color-fff">{{item.goodsClass.fGoodsClass}}</div>
							<div v-if="currentTab==item.goodsClass.fGoodsClassID" class="choose-box-triangle position-absolute"></div>
							<div v-if="currentTab==item.goodsClass.fGoodsClassID" class="choose-box-rectangle position-absolute"></div>
						</div>
						<div v-if="allCate.length>8" class="domain-box flex-column-center-center position-relative" @mouseenter="allCategoriesOver=true"
						 @mouseleave="allCategoriesOver=false">
							<div class="domain-box flex-column-center-center position-relative pointer">
								<i class="iconfont icon-quanbufenlei font-color-fff font-size20"></i>
								<div class="margin-t-6 font-size14 font-color-fff">全部分类</div>
							</div>
							<el-collapse-transition>
								<div v-if="allCategoriesOver" class="all-categories position-absolute padding-10">
									<div v-for="(item,index) of goldMallList2" :key="index">
										<div class="flex-row">
											<div class="all-categories-title font-size12 font-color-fff font-weight700">{{item.goodsClass.fGoodsClass}}</div>
										</div>
										<div class="flex-row-wrap font-size12 font-color-666">
											<div v-for="(item1,index1) of item.goodsClasses" :key="index1" class="all-categories-detailed pointer"
											 @click="selectAllDeteil(item.goodsClasses,item1.goodsClass.fGoodsClassID)">{{item1.goodsClass.fGoodsClass}}</div>
										</div>
									</div>
								</div>
							</el-collapse-transition>
						</div>
						<!-- <div v-for="(item,index) of goldMallList" :key="index" :class="item.isChoose?'choose-style':''" class="domain-box flex-column-center-center position-relative pointer"
						 @click="selectTable(item)">
							<i class="el-icon-menu font-color-fff" :class="item.isChoose?'font-size24':'font-size20'"></i>
							<div class="margin-t-6 font-size12 font-color-fff">{{item.title}}</div>
							<div v-if="item.isChoose" class="choose-box-triangle position-absolute"></div>
							<div v-if="item.isChoose" class="choose-box-rectangle position-absolute"></div>
						</div>
						<div class="domain-box flex-column-center-center position-relative" @mouseenter="allCategoriesOver=true"
						 @mouseleave="allCategoriesOver=false">
							<div class="domain-box flex-column-center-center position-relative pointer">
								<i class="el-icon-menu font-color-fff font-size20"></i>
								<div class="margin-t-6 font-size12 font-color-fff">全部分类</div>
							</div>
							<el-collapse-transition>
								<div v-if="allCategoriesOver" class="all-categories position-absolute padding-10">
									<div v-for="(item,index) of allCategoriesList" :key="index">
										<div class="flex-row">
											<div class="all-categories-title font-size12 font-color-fff font-weight700">{{item.title}}</div>
										</div>
										<div class="flex-row-wrap font-size12 font-color-666">
											<div class="all-categories-detailed pointer">全部</div>
											<div v-for="(item1,index1) of item.detailed" :key="index1" class="all-categories-detailed pointer">{{item1}}</div>
										</div>
									</div>
								</div>
							</el-collapse-transition>
						</div> -->
					</div>
				</div>
				<div class="table-bg-box position-absolute width-fill"></div>
			</div>
			<!-- <div class="maink table-detailed-box flex-row-wrap">
				<div v-for="(item,index) of tagList" :key="index" :class="currentTag==item.goodsClass.fGoodsClassID?'detailed-select-button':'detailed-button'"
				 class="margin-tb-10 padding-l-13 padding-r-13 flex-row-center-center margin-r-10 pointer" @click="selectDetailed(item.goodsClass.fGoodsClassID)">{{item.goodsClass.fGoodsClass}}</div>
			</div> -->
		</div>
	</div>
</template>

<script>
	import imageBox from '@/components/imageBox/imageBox.vue'
	import {
		mapGetters
	} from 'vuex';
	import parm from '@/components/loyout/publicList'
	export default {
		components:{
			imageBox
		},
		data() {
			return {
				search: '', //搜索框文字
				arr: parm.arr,
				allCategoriesOver: false, //全部分类显示
				allCate: [],
				goldMallList1: [],
				goldMallList2: [],
				indexSelected: true,
				currentTab: '',
				tagList: [],
				currentTag: '',
				goldMallList: [{
						id: 2,
						title: "金币商城首页",
						isChoose: true,
						detailed: [{
								name: "文件夹",
								isChoose: false
							},
							{
								name: "中性笔",
								isChoose: false
							},
							{
								name: "文件夹",
								isChoose: false
							},
							{
								name: "中性笔",
								isChoose: false
							},
							{
								name: "文件夹",
								isChoose: false
							},
							{
								name: "中性笔",
								isChoose: false
							}
						]
					},
					{
						id: 3,
						title: "办公文具",
						isChoose: false,
						detailed: [{
								name: "笔记本",
								isChoose: false
							},
							{
								name: "中性笔",
								isChoose: false
							},
							{
								name: "文件夹",
								isChoose: false
							},
							{
								name: "中性笔",
								isChoose: false
							},
							{
								name: "文件夹",
								isChoose: false
							},
							{
								name: "中性笔",
								isChoose: false
							}
						]
					},
					{
						id: 4,
						title: "办公设备",
						isChoose: false,
						detailed: [{
							name: "打印机",
							isChoose: false
						}]
					},
					{
						id: 5,
						title: "电脑配件",
						isChoose: false,
						detailed: [{
							name: "显卡",
							isChoose: false
						}]
					},
					{
						id: 6,
						title: "数码通讯",
						isChoose: false,
						detailed: [{
							name: "手机",
							isChoose: false
						}]
					},
					{
						id: 7,
						title: "办公电器",
						isChoose: false,
						detailed: [{
							name: "打印机",
							isChoose: false
						}]
					},
					{
						id: 8,
						title: "生活用品",
						isChoose: false,
						detailed: [{
							name: "牙刷",
							isChoose: false
						}]
					},
					{
						id: 9,
						title: "食品饮料",
						isChoose: false,
						detailed: [{
							name: "可乐",
							isChoose: false
						}]
					},
					{
						id: 10,
						title: "商务礼品",
						isChoose: false,
						detailed: [{
							name: "礼品",
							isChoose: false
						}]
					}
				], //金币商场导航栏数据
				detailedList: [], //选中的table明细
				allCategoriesList: [{
						title: "食品饮料",
						detailed: ["牛奶", "酸奶", "咖啡", "可乐", "花茶", "冲饮谷物", "红茶", "黑茶", "白茶", "茉莉花茶"]
					},
					{
						title: "办公文具",
						detailed: ["文件夹", "笔筒", "计算器", "笔记本"]
					},
					{
						title: "电脑配件",
						detailed: ["鼠标", "键盘", "显卡", "主板", "机箱", "电源", "散热器", "显示器支架", "刻录机/光驱", "声卡/扩展卡", "装机配件", "组装电脑", "USB分线器",
							"CPU", "键盘", "显卡", "主板", "机箱", "电源", "散热器", "显示器支架", "刻录机/光驱", "声卡/扩展卡", "装机配件", "组装电脑", "USB分线器"
						]
					},
					{
						title: "办公设备",
						detailed: ["打印机", "传真设备", "扫描设备", "碎纸机", "白板", "保险柜", "投影仪"]
					},
				], //全部分类
				logoUrl: '',
			};
		},
		computed: {
			...mapGetters(['getThemeName', "getLogoUrl"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
    deactivated(){
      this.getGoldMallList()
      this.logoUrl = this.$store.getters.getLogoUrl ? this.$store.getters.getLogoUrl : this.weblogUrl;
    },
		// mounted() {
		// 	this.getGoldMallList()
		// 	this.logoUrl = this.$store.getters.getLogoUrl ? this.$store.getters.getLogoUrl : this.weblogUrl;
		// },
    mounted() {
      // this.getGoldMallList()
      let goldenNavigations = sessionStorage.getItem('golden-navigations')
      if (goldenNavigations) {
        goldenNavigations = JSON.parse(goldenNavigations)
        this.initData(goldenNavigations)
      } else {
        this.getGoldMallList()
      }
      this.logoUrl = this.$store.getters.getLogoUrl ? this.$store.getters.getLogoUrl : this.weblogUrl;
    },
		methods: {
			//删除掉存的页码
			deleteSession(){
				if(sessionStorage.getItem('LikePage') != null){
					sessionStorage.removeItem('LikePage')
				}
			},
			// 获取金币商城分类
			// getGoldMallList() {
			// 	this.ApiRequestPost('api/mall/goods/goods-class/get-golden-navigations', {}).then(result => {
			// 		console.log(result);
			// 		this.allCate = result.obj.goodsClasses
			// 		// 前8个一级分类
			// 		this.goldMallList1 = this.allCate.slice(0, 8)
			// 		// 超过8个后展示在全部分类的
			// 		this.goldMallList2 = this.allCate.slice(8)
			// 		// tab下的tag标签二级分类,默认是第一个一级分类下的二级分类
			// 		this.tagList = this.goldMallList1[0].goodsClasses
			// 		// 默认第一个tag标签是选中的
			// 		this.currentTag = this.tagList[0].goodsClass.fGoodsClassID
			// 		// console.log(this.allCate);
			// 		// console.log('goldMallList1',this.goldMallList1);
			// 		// console.log('goldMallList2',this.goldMallList2);
			// 	}, rej => {})
			// },
      // 获取金币商城分类
      getGoldMallList() {
        this.ApiRequestPost('api/mall/goods/goods-class/get-golden-navigations', {}).then(result => {
          console.log(result);
          sessionStorage.setItem('golden-navigations',JSON.stringify(result))
          this.initData(result)
          // console.log(this.allCate);
          // console.log('goldMallList1',this.goldMallList1);
          // console.log('goldMallList2',this.goldMallList2);
        }, rej => {})
      },
      initData (result) {
        this.allCate = result.obj.goodsClasses
        // 前8个一级分类
        this.goldMallList1 = this.allCate.slice(0, 8)
        this.goldMallList1.forEach(item=>{
          if(item.goodsClass.fGoodsClass == "运动户外") {
            item.annex = 'iconfont icon-yundongjianshen'
          }else if(item.goodsClass.fGoodsClass == "礼品箱包"){
            item.annex = 'iconfont icon-xiangbao'
          }else if(item.goodsClass.fGoodsClass == "营养保健"){
            item.annex = 'iconfont icon-shiwuyingyang'
          }else if(item.goodsClass.fGoodsClass == "服装珠宝"){
            item.annex = 'iconfont icon-zhubaoshoushi'
          }else if(item.goodsClass.fGoodsClass == "食品饮料"){
            item.annex = 'iconfont icon-shipinyinliao'
          }else if(item.goodsClass.fGoodsClass == "家用电器"){
            item.annex = 'iconfont icon-jiayongdianqi'
          }else if(item.goodsClass.fGoodsClass == "家居厨具"){
            item.annex = 'iconfont icon-jiaju'
          }else if(item.goodsClass.fGoodsClass == "手机数码"){
            item.annex = 'iconfont icon-shouji-F'
          }
        })
        console.log(this.goldMallList1,'分类')
        // 超过8个后展示在全部分类的
        this.goldMallList2 = this.allCate.slice(8)
        // tab下的tag标签二级分类,默认是第一个一级分类下的二级分类
        this.tagList = this.goldMallList1[0].goodsClasses
        // 默认第一个tag标签是选中的
        this.currentTag = this.tagList[0].goodsClass.fGoodsClassID
      },
			//选中金币商城首页tab标签
			selectIndex(e) {
				this.currentTab = ''
				this.indexSelected = true
				this.$router.push({
					path: "/GoldMall",
				});
				//顺便删除页码的缓存
				if(sessionStorage.getItem('LikePage') != null){
					sessionStorage.removeItem('LikePage')
				}
			},
			//tab下的tag标签点击事件
			selectDetailed(id) {
				this.$router.push({
					path: "/goldMall/goldGoods",
					query: {
						id: id
					}
				});
				this.currentTag = id
			},
			// 全部分类下的二级分类点击
			selectAllDeteil(val, id) {
				console.log(val);
				console.log(id);
				this.tagList = val
				this.currentTag = id
				this.$router.push({
					path: "/goldMall/goldGoods",
					query: {
						id: id
					}
				});
				this.allCategoriesOver = false
			},
			// tab点击事件
			selectCate(val) {
				console.log(val);
				console.log(val.goodsClass.fGoodsClassID);
				this.currentTab = val.goodsClass.fGoodsClassID
				this.indexSelected = false
				this.tagList = val.goodsClasses
				this.currentTag = this.tagList[0].goodsClass.fGoodsClassID
				this.$router.push({
					path: "/goldMall/goldGoods",
					query: {
						id: val.goodsClass.fGoodsClassID
					}
				});
			},
			// 点击搜索按钮
			searchClick(event) {
				// 按下回车键失去焦点
				event.target.blur()
				console.log(this.search);
				if (this.search != "") {
					// 跳转到全部商品页面,并传递搜索条件
					this.$router.push({
						path: "/goldMall/goldGoods",
						query: {
							search: this.search
						}
					});
					// this.search = ''
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.bg-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
		}
	}

	.gold-mall-head-box {
		margin-top: 29px;
		box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
		z-index: 1;
	}

	.gold-mall-head-tatle-box {
		margin: auto;
		height: 89px;
		
	}

	.search-box {
		// margin-left: 200px;
	}

	.search-button {
		user-select: none;
	}

	/deep/.el-input__inner {
		border: 1px solid #40c6a9 !important;
		width: 423px;
		height: 42px !important;
		border-radius: 0px;
	}

	/deep/.el-input-group__append {
		background-color: #40c6a9;
		border: 1px solid #40c6a9;
		border-radius: 0px;
		width: 66px;
		text-align: center;
	}

	.table-box {
		height: 72px;
	}

	.table-bg-box {
		height: 60px;
		background-color: #40c6a9;
		bottom: 0;
		left: 0;
	}

	.table-inner-box {
		margin: 0 auto;
		z-index: 1;
	}

	.domain-box {
		width: 100px;
		height: 60px;
	}

	.domain-box:hover {
		background-color: #ffcc00;
	}

	.choose-style {
		background-color: #ffcc00;
	}

	.choose-box-triangle {
		width: 0;
		height: 0;
		border-bottom: 12px solid #997a00;
		border-right: 12px solid transparent;
		top: -12px;
		right: -12px;
	}

	.choose-box-rectangle {
		background-color: #ffcc00;
		width: 100px;
		height: 12px;
		top: -12px;
		left: 0;
	}

	.table-detailed-box {
		margin: 0 auto;
		// width: 200px;
	}

	.detailed-button {
		// width: 60px;
		height: 30px;
		border: 1px solid #cccccc;
		color: #666666;
		border-radius: 30px;
	}

	.detailed-select-button {
		// width: 60px;
		height: 30px;
		border: 1px solid #40c6a9;
		background-color: #40c6a9;
		color: #FFFFFF;
		border-radius: 30px;
	}

	.all-categories {
		top: 60px;
		right: 0;
		background-color: #F8F9FE;
		box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
		min-width: 1080px;
		max-width: 1080px;
		z-index: 2;
	}

	.all-categories-title {
		padding: 5px 10px;
		background-color: #333333;
		// box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
		margin-bottom: 10px;
	}

	.all-categories-detailed {
		margin: 0px 20px 10px 0;
	}

	.all-categories-detailed:hover {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}
	.font-size15{
			font-size: 15px;
		}
		.marato {
			margin: 24px auto;
		}
		.LandlineImage {
			width: 25px;
			height: 25px;
		}
</style>
